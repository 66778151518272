import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 关于我们/api/getNavigationList

export function getSearchDetailNavi(data,langEnv=2){
  return request({
      url:`${baseURL.api}/api/searchAboutUs`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
