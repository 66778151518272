<template>
  <div class="brand-spike">
    <nav-bar :isFixed="true" :title="$t(`${data.name}`)" :bgc="'background-color: #f8f8f8;'"></nav-bar>
    <div
      :class="{ about:!isMobile() }"
      v-html="aboutHtml"
      @click="getImgChange($event)"
    ></div>
  </div>
</template>

<script setup>
import { getSearchDetailNavi } from "@/api/about.js";
import { onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import { ImagePreview } from "vant";
import Hammer from "hammerjs";
const route = useRoute();
const aboutHtml = ref("");
const data=ref('')
const getImgChange = e => {
  if (e.target.nodeName == "IMG") {
    ImagePreview({
      images: [e.target.currentSrc],
      showIndex: false,
      loop: false
    });
  }
};
const isMobile = () => {
  var u = navigator.userAgent;
  const isAndroid =
    (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
    u.indexOf("MMMMessenger") > -1; //android终端
  const isIos =
    !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
    u.indexOf("MMMMessenger") > -1; //ios终端
  return isAndroid || isIos;
};
onMounted(async () => {
  const langEnv = route.query.langEnv;
  const id = route.query.id;
  const res = await getSearchDetailNavi({ id: id }, langEnv);
  aboutHtml.value = res.data.content;
  data.value=res.data
});
</script>

<style scoped lang="scss">
.brand-spike {
  padding: 8px;
}
.about {
  padding-top: 44px;
}
</style>
